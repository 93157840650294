export default class Totals {
    subtotal = 0;
    discountTotal = 0;
    total = 0;
    includedTax = 0;
    discounts = {};
    assign(other) {
        Object.assign(this, other);
    }
}
