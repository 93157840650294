export default class CustomOrderItem {
    productTitle;
    productStartLocationName;
    productEndLocationName;
    dateFrom = new Date();
    dateTo = new Date();
    bookingMeta = {};
    prices = {};
    resourceReservations = {};
}
